import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"



const IndexPage = () => {
    const root = "/img/download/tribe-night"

    return (
        <Layout pageTitle="Tribe Night - Templates">
            <Row>
                <Col>
                    <Link to="/events/tribe-night">Back</Link>
                </Col>
            </Row>
                
            <ImageDownload
            root ="/img/download/tribe-night"
            filename=""
            name=""
            text=""
            text2=""
            squareHref=""
            horizontalHref=""
            copyHref=""
            />  
           
            

            
        </Layout>

        
    )
}

export default IndexPage
